import React, { useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import AdminLoginFeature from '../../features/AdminLoginFeature'
import { getAllManufacturersAPI, getAllOperatorsAPI } from '../../utils/api'
import CustomLoader from '../../components/CustomLoader'
import type { ManufactureListType, OperatorListType } from '../../features/AdminLoginFeature/types'
import CustomSnackBar from '../../components/CustomSnackBar'
import { SnackBarMessageType } from '../../consts/SnackbarMessageTypes'

const AdminLoginPage: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [manufacturerList, setManufacturerList] = useState<ManufactureListType[]>([])
  const [operatorList, setoperatorsList] = useState<OperatorListType[]>([])
  const platform = localStorage.getItem('platform')
  const requestingAs = localStorage.getItem('requesting_as')

  const [isError, setIsError] = useState<boolean>(false)

  useAsyncEffect(async () => {
    try {
      const [manufacturer, operators] = await Promise.all([
        getAllManufacturersAPI(platform, requestingAs),
        getAllOperatorsAPI(platform, requestingAs)
      ])
      if (manufacturer?.success === true && operators?.success === true) {
        setIsLoading(false)
        setManufacturerList(manufacturer?.payload)
        setoperatorsList(operators?.payload)
      }
    } catch (err) {
      setIsLoading(false)
      setIsError(true)
    }
  }, [])

  return (
    <>
      {isLoading
        ? <CustomLoader />
        : <AdminLoginFeature manufacturerList={manufacturerList} operatorList={operatorList} />
      }
      <CustomSnackBar isOpen={isError} onClose={setIsError} message={'Unable to fetch data, Please login again'} duration={5000} messageType={SnackBarMessageType.ERROR} />
    </>
  )
}

export default AdminLoginPage
