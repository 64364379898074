import React, { useState } from 'react'
import Box from '@mui/material/Box'
import CustomAutoComplete from '../../../components/CustomAutoComplete'
import type { PlatformListType } from '../types'

interface RequestingAsProps {
  platformList: PlatformListType[]
}

const RequestingAsDropDown: React.FC<RequestingAsProps> = ({ platformList }): JSX.Element => {
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformListType | null>(null)

  const handleAutocompleteChange = (event: any, newValue: PlatformListType | null): void => {
    setSelectedPlatform(newValue)
    if (newValue !== null) {
      localStorage.setItem('requesting_as', newValue.value)
    }
  }

  return (
    <>
      <Box mb={2} mt={2}>
        <CustomAutoComplete
          options={platformList}
          getOptionLabel={(platformList) => `${platformList.platform}`}
          value={selectedPlatform}
          onChange={handleAutocompleteChange}
          placeholder="Select Platform (Optional)"
        />
      </Box>
    </>
  )
}

export default RequestingAsDropDown
