export const SampleManufacturerQuestions = [
  { text: 'Which locations are buying the largest amount of my products?' },
  { text: 'What are my most sold products this year?' },
  { text: 'What is my biggest market segment?' },
  { text: 'Which category has the highest sales?' }
]

export const SampleOperatorQuestions = [
  { text: 'How much did I earn in rebates in 2024?' },
  { text: 'Are there any similar items to my most purchased item?' },
  { text: 'What are my most purchased items?' },
  { text: 'What does Buyers Edge Platform (BEP) do?' }
]
