import React, { useState, useEffect, useRef } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import {
  StyledChatMessagesWrapper,
  StyledImage,
  StyledInput,
  StyledChatMessageFooter,
  StyledInputWrapper,
  StyledMessageList,
  StyledLoadingIndicator,
  StyledText,
  StyledTextWrapper,
  StyledLoadingBox,
  StyledNextQuestions,
  StyledInputAndButtonWrapper
} from './styled'
import UserMessage from './UserMessage'
import BotMessage from './BotMessage'
import { chatBotAPI, getSessionChatHistory } from '../../../utils/api'
import { getCurrentDay, getCurrentTime } from '../../../utils/getCurrentTime'
import SendIconActive from '../../../assets/SendIconActive.svg'
import CustomSnackBar from '../../CustomConversationSnackBar'
import { SnackBarMessageType } from '../../../consts/SnackbarMessageTypes'
import type { ChatListT } from './types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'
import InitialScreen from './InitialScreen'
import getOrientation from '../../../utils/getOrientation'
import NextQuestion from './NextQuestion'
import NewChatButton from '../../../features/NewChatButton'
import getForcedMobile from '../../../utils/forceMobile'
import { parsePlatformId } from '../../../utils/parsePlatformId'

interface ChatConversationSectionProps {
  session: string
  onOpenNewChat: () => void
}

const ChatConversationSection: React.FC<ChatConversationSectionProps> = ({ session, onOpenNewChat }): JSX.Element => {
  const theme = useTheme()
  const isPortrait = getOrientation()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const platformId = parsePlatformId(localStorage.getItem('platform_id') ?? '')
  const platform = localStorage.getItem('platform')
  const requestingAs = localStorage.getItem('requesting_as')
  const [inputText, setInputText] = useState<string>('')
  const [sampleQuestion, setSampleQuestion] = useState<string>('')
  const [chatList, setChatList] = useState<ChatListT[]>([])
  const [isFeedbackSubmit, setIsFeedbackSubmit] = useState<boolean>(false)
  const [recievedChatbotAnswer, setRecievedChatbotAnswer] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showInitialScreen, setShowInitialScreen] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [nextQuestions, setNextQuestions] = useState<string[]>([])
  const marketingQuestion = localStorage.getItem('question') ?? ''
  const messageListRef = useRef<HTMLDivElement | null>(null)

  const loadSessionHistory = async (): Promise<any> => {
    if (session !== null) {
      try {
        const res = await getSessionChatHistory(session, platformId, platform, requestingAs)
        if (res?.success === true && res?.payload?.length > 0) {
          const chatHistory : (ChatListT[]) = []
          res.payload.forEach((message: any) => {
            const rawUserDate = new Date(message.user_input_datetime)
            const rawChatbotDate = new Date(message.chatbot_response_datetime)
            const userChat = { isUser: true, text: message.user_input, timeStamp: (getCurrentDay() === rawUserDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric' })) ? rawUserDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : rawUserDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) }
            const chatbotChat = { isUser: false, text: message.chatbot_response, chatRecordId: message.id, timeStamp: (getCurrentDay() === rawChatbotDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric' })) ? rawChatbotDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : rawChatbotDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) }
            chatHistory.push(userChat)
            chatHistory.push(chatbotChat)
          })
          setShowInitialScreen(false)
          setChatList(chatHistory)
          setIsFeedbackSubmit(false)
        }
        if (res?.success === true && res?.payload?.length <= 0) {
          if (marketingQuestion !== '') {
            setShowInitialScreen(false)
          } else {
            setShowInitialScreen(true)
            setChatList([])
            setIsFeedbackSubmit(false)
          }
        }
      } catch (err: any) {
        setIsError(true)
        setErrorMessage(err?.response?.data?.message)
      }
    }
  }

  const handleSubmit = async (): Promise<any> => {
    if (inputText.trim() === '' && sampleQuestion === '') {
      return
    }
    setShowInitialScreen(false)
    setChatList([
      ...chatList,
      { text: sampleQuestion !== '' ? sampleQuestion : inputText, isUser: true, timeStamp: getCurrentTime() }
    ])
    setSampleQuestion('')
    setInputText('')
    setIsLoading(true)
    setIsFeedbackSubmit(true)
    setRecievedChatbotAnswer(false)

    if (messageListRef.current !== null) {
      messageListRef.current?.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }

    // API CALL
    const chatBotData = {
      user_input: sampleQuestion !== '' ? sampleQuestion : inputText,
      session_id: session,
      platform_id: platformId,
      internal_user: isAdmin,
      user_name: localStorage.getItem('user_name'),
      user_email: localStorage.getItem('user_email')
    }

    try {
      setNextQuestions([])
      const res = await chatBotAPI(chatBotData, platform, requestingAs)
      if (res?.success === true) {
        setChatList((prevChatList) => [
          ...prevChatList,
          {
            text: res?.payload?.chatbot_output,
            isUser: false,
            timeStamp: getCurrentTime(),
            chatRecordId: res?.payload?.chat_record_id
          }
        ])
        setNextQuestions(res?.payload?.next_questions)
        setRecievedChatbotAnswer(true)
      }
    } catch (err: any) {
      setIsError(true)
      setErrorMessage(err?.response?.data?.message)
      setIsFeedbackSubmit(false)
      setRecievedChatbotAnswer(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEnterKey = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (e.key === 'Enter') {
      e.preventDefault()
      await handleSubmit()
    }
  }

  const handleRegenerate = async (prompt: string): Promise<void> => {
    setSampleQuestion(prompt)
  }

  const handlePregeneratedQuestion = async (prompt: string): Promise<void> => {
    setSampleQuestion(prompt)
  }

  useEffect(() => {
    if (messageListRef.current !== null) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
    if (localStorage.getItem('internal_user') === 'true') {
      setIsAdmin(true)
    }
  }, [chatList])

  useAsyncEffect(async () => {
    await loadSessionHistory()
  }, [session])

  const handleIcon = (): string => {
    return SendIconActive
  }

  const handlePlaceholderText = (): string => {
    if (!recievedChatbotAnswer) return 'Waiting for chatbot response...'
    else return 'Ask your question here...'
  }

  const handleSampleQuestion = (question: string): void => {
    setSampleQuestion(question)
  }

  useAsyncEffect(async () => {
    await handleSubmit()
  }, [sampleQuestion])

  useAsyncEffect(async () => {
    if (marketingQuestion !== '') {
      setSampleQuestion(marketingQuestion)
      localStorage.setItem('question', '')
    }
  }, [])

  return (
    <>
      <StyledChatMessagesWrapper isMobile={isMobile || isPortrait || forcedMobile}>
        {showInitialScreen && true
          ? <InitialScreen handleSampleQuestion={handleSampleQuestion} />
          : (
            <StyledMessageList isMobile={isMobile || isPortrait || forcedMobile} ref={messageListRef}>
              {chatList?.map((message: ChatListT, index: number) => {
                return (
                  message.isUser
                    ? <UserMessage message={message} index={index} />
                    : <BotMessage message={message} userMessage={chatList[index - 1]} isRegenerated={chatList[index - 1]?.text === chatList[index - 3]?.text} handleRegenerate={handleRegenerate} chatRecordId={message?.chatRecordId} isFeedbackSubmit={setIsFeedbackSubmit} />
                )
              })}
              {isLoading && <StyledLoadingIndicator />}
              {nextQuestions?.length > 0 &&
              <StyledNextQuestions>
                {nextQuestions?.map((question: string, index: number) => {
                  return (
                    <NextQuestion question={question} key={index} handleClick={ handlePregeneratedQuestion }/>
                  )
                })}
              </StyledNextQuestions>
              }
            </StyledMessageList>)}
        <StyledChatMessageFooter isMobile={isMobile || isPortrait || forcedMobile}>
          <StyledInputAndButtonWrapper isMobile={isMobile || isPortrait || forcedMobile}>
            <StyledInputWrapper isMobile={isMobile || isPortrait || forcedMobile}>
              <StyledInput
                type='text'
                id='message-input'
                isfeedbacksubmit={isFeedbackSubmit}
                isMobile={isMobile || isPortrait || forcedMobile}
                placeholder={handlePlaceholderText()}
                value={inputText}
                disabled={!recievedChatbotAnswer}
                onChange={(e) => { setInputText(e.target.value) }}
                onKeyDown={(e) => { handleEnterKey(e).catch(console.error) }}
              />
              {!recievedChatbotAnswer &&
              <StyledLoadingBox isMobile={isMobile || forcedMobile}>
                <CircularProgress size={32}/>
              </StyledLoadingBox>
              }
              {recievedChatbotAnswer &&
              <StyledImage
                src={handleIcon()}
                alt='Send Icon'
                isMobile={isMobile || isPortrait || forcedMobile}
                onClick={() => { handleSubmit().catch(console.error) }}
              />
              }
            </StyledInputWrapper>
            {((!isMobile || !forcedMobile) && chatList?.length > 0) &&
            <NewChatButton handleClick={() => { onOpenNewChat() }}/>
            }
          </StyledInputAndButtonWrapper>
          <StyledTextWrapper>
            <StyledText>
              The recommended products and categories may not align with your specific requirements or business needs; these are provided solely as suggestions. Chatbot Erni can make mistakes. Check important info. <a href='https://mydiningalliance.com/chatbottermsofservice/' target='_blank' rel='noopener noreferrer'>Terms of Service</a>
            </StyledText>
          </StyledTextWrapper>
        </StyledChatMessageFooter>
      </StyledChatMessagesWrapper >
      <CustomSnackBar isOpen={isError} onClose={setIsError} message={errorMessage} duration={3000} messageType={SnackBarMessageType.ERROR} />
    </>
  )
}

export default ChatConversationSection
