import type { ChatBotDataT, FeedbackT, ReactionT } from '../components/ChatBotScreen/ChatConversationSection/types'
import axiosInstance from './apiInstance'

// Chat Endpoint
export const chatBotAPI = async (chatData: ChatBotDataT, platform: string | null, requestingAs: string | null): Promise<any> => {
  try {
    const response = await axiosInstance.post('/chat', chatData, { headers: { platform, requesting_as: requestingAs } })
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}

// Microsoft User Login Endpoint
export const microsoftUserLoginAPI = async (loginData: { token: string }): Promise<any> => {
  try {
    const response = await axiosInstance.post('/user/login/microsoft', loginData)
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}

// Get default manufacture list
export const getAllManufacturersAPI = async (platform: string | null, requestingAs: string | null): Promise<any> => {
  try {
    const response = await axiosInstance.get('/manufacturer/all', { headers: { platform, requesting_as: requestingAs } })
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}

// Get default operator list
export const getAllOperatorsAPI = async (platform: string | null, requestingAs: string | null): Promise<any> => {
  try {
    const response = await axiosInstance.get('/operator/search', { headers: { platform, requesting_as: requestingAs } })
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}

// Get serached operator list
export const getAllOperatorsByInputAPI = async (inputValue: string, limit: number, platform: string | null, requestingAs: string | null): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/operator/search?name=${inputValue}&limit=${limit}`, { headers: { platform, requesting_as: requestingAs } })
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}

// send response Reaction
export const sendResponseReaction = async (chatRecordId: string | undefined, feedback: ReactionT | FeedbackT, platform: string | null, requestingAs: string | null): Promise<any> => {
  try {
    const response = await axiosInstance.patch(`/chat/record/feedback/${chatRecordId}`, feedback, { headers: { platform, requesting_as: requestingAs } })
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}

// Get searched messages by session ID
export const getSessionChatHistory = async (sessionId: string | null, platformId: string[] | null, platform: string | null, requestingAs: string | null): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/chat/record/search?session_id=${sessionId}&platform_id=${platformId?.toString()}`, { headers: { platform, requesting_as: requestingAs } })
    return response.data
  } catch (error) {
    console.log('Error', error)
    throw error
  }
}
